import React from "react";
import Layout from "../../components/Layout";
import { graphql, Link } from "gatsby";
import Buttonv3 from "../../components/buttons/buttonv3";
import PropTypes from "prop-types";
import HeroComponentV2 from "../../components/hero/HeroComponentV2";

const ServicesNewPage = ({ data }) => {
  return (
    <Layout>
      <div className="home">
        <HeroComponentV2
          heroBackground={"#FFE6D2"}
          subHeading={"Our services"}
          title={data.strapiSitePages.service.pageTitle}
          description={data.strapiSitePages.service.description}
          footerComponent={() => (
            <Buttonv3 title={"Read More  →"} toLink="/services/overview" />
          )}
          rightComponent={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="links-vertical">
                {data.allStrapiServices.nodes.map((v, k) => (
                  <Link key={k} to={v.slug}>
                    {v.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
        />
        {data.strapiSitePages.service.sections.map((v, k) => (
          <ServicePageBody key={k} kk={k} data={v} />
        ))}
      </div>
    </Layout>
  );
};

const ServicePageBody = ({ kk, data }) => (
  <div className="HomeAboutUs" style={{ backgroundColor: "ECF8F9" }}>
    <div
      className="section-bg"
      style={{ backgroundColor: data.sectionColor }}
    />
    <div
      className={`onlyText ${kk % 2 !== 0 ? "mobile-view" : ""}`}
      style={{ marginBottom: 10 }}
    >
      <div className="sub1">
        <div className="ti">{data.subTitle}</div>
        <h2 className="homeAboutTitle">{data.title}</h2>
        <div className="sub22">
          {data.body}
          <br />
          <br />
        </div>
      </div>

      <div
        className="sub2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={data.image.localFile.childImageSharp.fluid.src}
          alt=""
          width={"80%"}
        />
      </div>
    </div>
  </div>
);

ServicesNewPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  {
    allStrapiServices {
      nodes {
        title
        titleHindi
        slug
        icon {
          localFile {
            publicURL
          }
        }
      }
      totalCount
    }

    strapiSitePages {
      service {
        pageTitle
        description
        sections {
          body
          subTitle
          title
          id
          sectionColor
          image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ServicesNewPage;
