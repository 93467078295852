import React from "react";
import "./Herov2.style.scss";

export default function HeroComponentV2({
  title,
  subHeading,
  description,
  footerComponent = () => {},
  rightComponent = undefined,
  flexStyle = {},
  heroBackground = undefined,
}) {
  const isRightComponent = typeof rightComponent === "function";
  const isFooterComponent = typeof footerComponent === "function";
  return (
    <div
      className="hero herov2"
      style={{
        backgroundRepeat: "repeat, no-repeat",
        backgroundSize: "auto, cover",
        background: heroBackground || "white",
      }}
    >
      <div className="wrapper_max" style={{ padding: "10px 0px" }}>
        <div className="flexClass" style={flexStyle}>
          <div style={{ flex: 1 }}>
            <div className="ti">{subHeading.toUpperCase()}</div>
            <h1 style={{ color: "black" }}>{title}</h1>
            <div className="description-herov2">{description}</div>
            {isFooterComponent && (
              <div className="footer-herov2">{footerComponent()}</div>
            )}
          </div>
          {isRightComponent && (
            <div style={{ flex: 1 }}>{rightComponent()}</div>
          )}
        </div>
      </div>
    </div>
  );
}
